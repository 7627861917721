import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import ViewTableManagersTotalSale from '../views/ViewTableManagersTotalSale';
import { loadStartAction } from './../data/actions/loadStartActions';
import { managerItemSalesTotalSumSelector, managerSalesTableSelector } from '../data/selectors/managerListSelector';
import { getManagerSaleListAction } from '../data/actions/managerListActions';
import { userDataSelector } from './../data/selectors/userDataSelector';
import { addUserLogDateApi } from '../api/logDataApi';


const DashboardManagerSalesContainer = () => {

    const userData = useSelector(userDataSelector)

    const yearData = userData.settingsData.yearData

    const [year, setYear] = useState(yearData)

    const dispatch = useDispatch()
    const managerItemSalesTotalSum = useSelector(managerItemSalesTotalSumSelector(year))

    const handleChangeYear = (event) => {
        setYear(event.target.value)
    }

    useEffect(() => {
        dispatch(getManagerSaleListAction(userData, year))
        addUserLogDateApi(userData, '/')
        // dispatch(loadStartAction(userData))
    }, [year])

    return (
        // <div></div>
        <ViewTableManagersTotalSale
            items={managerItemSalesTotalSum}
            year={year}
            userData={userData}
            handleChangeYear={handleChangeYear}
        />
    )
}

export default DashboardManagerSalesContainer
