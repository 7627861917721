import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getAllManagerSaleListAction, loadMainManagerListAction } from '../data/actions/managerListActions';
import { adminDataSelector, userDataSelector } from './../data/selectors/userDataSelector';
import AdminViewTableManagersTotalSale from './../views/AdminViewTableManagersTotalSale';
import { managerListSalesTotalSumSelector } from './../data/selectors/managerListSelector';
import { addUserLogDateApi } from '../api/logDataApi';


const AdminDashboardManagerSalesContainer = () => {

    const [totalSum, setTotalSum] = useState(0);

    const dispatch = useDispatch()
    const adminData = useSelector(adminDataSelector)
    const userData = useSelector(userDataSelector)
    const [year, setYear] = useState(adminData.settingsData.yearData)
    const [location, setLocation] = useState(adminData.adminLocale);
    const managerListSalesTotalSum = useSelector(managerListSalesTotalSumSelector(year))

    const handleChangeYear = (event) => {
        setYear(event.target.value)
    }

    useEffect(() => {
        localStorage.setItem('location', JSON.stringify(location));
        dispatch(getAllManagerSaleListAction(adminData, year))
    }, [year])

    useEffect(() => {
        dispatch(loadMainManagerListAction(adminData, location))
        addUserLogDateApi(userData, '/admin/dashboard')
    }, []);

    return (
        <AdminViewTableManagersTotalSale
            managers={managerListSalesTotalSum}
            adminData={adminData}
            setLocation={setLocation}
            location={location}
            setTotalSum={setTotalSum}
            totalSum={totalSum}
            year={year}
            handleChangeYear={handleChangeYear}
        />
    )
}

export default AdminDashboardManagerSalesContainer
