import axios from "axios";
import { BACKEND_DOMAIN } from "../data/constants/statuses";

export const getChartDataApi = async (userData) => {
    const { token, managerGroup, role, managerLocale, adminLocale } = userData

    const locale = adminLocale || managerLocale

    const cityExcluded = role !== 'admin' ? '&mainCity_ne=Краснодар' : '';

    const res = await axios
        .get(`${BACKEND_DOMAIN}/everydays-managers-sales?_limit=-1${cityExcluded}&_locale=${locale}`,
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }).then(response => {
                return response.data
            });
    // console.log("🚀 ~ file: updateDataApi.js ~ line 18 ~ getUpdateDataApi ~ res", res)
    return res;
}