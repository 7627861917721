import axios from 'axios';
import { gql } from '@apollo/client';
import client from '../apolloClient';
import { BACKEND_DOMAIN } from './../data/constants/statuses';

// const GET_MAIN_MANAGERS = gql`
//   query GetMainManagers($locale: String!) {
//     managers(where: { manager_status: "main", _locale: $locale }) {
//       id
//       managerName
//       bonusPercent
//       manager_status
//       sortNumber
//     }
//   }
// `;

// const GET_ALL_MANAGERS = gql`
//   query GetAllManagers($locale: String!) {
//     managers(where: {_locale: $locale}) {
//       id
//       managerName
//       bonusPercent
//       manager_status
//       sortNumber
//     }
//   }
// `;

export const getMainManagerListApi = async (userData, location) => {
    const { token } = userData
    const res = axios
        .get(`${BACKEND_DOMAIN}/managers?_limit=-1&_locale=${location}&manager_status=main`,
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }).then(response => {
                return response.data
            });
    return res;
}

export const getAllManagerListApi = async (userData, location) => {
    const { token } = userData
    const res = axios
    .get(`${BACKEND_DOMAIN}/managers?_limit=-1&_locale=${location}`,
        {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then(response => {
            return response.data
        });
return res;
}

export const getManagerSaleListApi = async (userData, year) => {
    const { token, managerId, managerLocale } = userData

    const res = axios
        .get(`${BACKEND_DOMAIN}/managers-sales?_limit=-1&_locale=${managerLocale}&manager=${managerId}&year=${year}`,
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }).then(response => {
                return response.data
            });
    return res;
}

export const getAllManagerSaleListApi = async (userData, year) => {
    const { token, adminLocale } = userData

    const res = axios
        .get(`${BACKEND_DOMAIN}/managers-sales?_limit=-1&_locale=${adminLocale}&year=${year}`,
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }).then(response => {
                return response.data
            });
    return res;
}

export const updateManagerSaleFieldByIdApi = async (fieldId, data, userData) => {
    const { token } = userData
    const res = axios
        .put(`${BACKEND_DOMAIN}/managers-sales/${fieldId}`, data, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        .then(response => {
            return response.status
        });
    return res;
}