 const mytext =

`
1CClientBankExchange
ВерсияФормата=1.03
Кодировка=Windows
Отправитель=СБИС
ДатаСоздания=13.01.2025
ВремяСоздания=18:29:00
ДатаНачала=13.01.2025
ДатаКонца=13.01.2025
РасчСчет=40802810138000060505
СекцияДокумент=Платежное поручение
Номер=3
Дата=04.01.2025
Сумма=2472.00
ПлательщикСчет=40702810413660007793
Плательщик=ОБЩЕСТВО С ОГРАНИЧЕННОЙ ОТВЕТСТВЕННОСТЬЮ "МЕДИЦИНСКИЙ ЦЕНТР "ЛАЗЕР"
ПлательщикИНН=3250073832
Плательщик1=ОБЩЕСТВО С ОГРАНИЧЕННОЙ ОТВЕТСТВЕННОСТЬЮ "МЕДИЦИНСКИЙ ЦЕНТР "ЛАЗЕР"
ПлательщикРасчСчет=40702810413660007793
ПлательщикБанк1=Филиал "Центральный" Банка ВТБ (ПАО)
ПлательщикБанк2=Москва
ПлательщикБИК=044525411
ПлательщикКорсчет=30101810145250000411
ПолучательСчет=40802810138000060505
ДатаПоступило=06.01.2025
Получатель=ИП Цоллер Станислав Владимирович
ПолучательИНН=561018779543
Получатель1=ИП Цоллер Станислав Владимирович
ПолучательРасчСчет=40802810138000060505
ПолучательБанк1=ПАО Сбербанк
ПолучательБанк2=Москва
ПолучательБИК=044525225
ПолучательКорсчет=30101810400000000225
КодНазПлатежа=
ВидОплаты=01
Код=0
НазначениеПлатежа=ОПЛАТА ПО ДОГОВОРУ №2406 ОТ 31.01.2023 ГОДА, ЗА КОСМЕТОЛОГИЧЕСКУЮ ПРОДУКЦИЮ (2 ПОЗ.), НДС НЕ ОБЛАГАЕТСЯ
НазначениеПлатежа1=ОПЛАТА ПО ДОГОВОРУ №2406 ОТ 31.01.2023 ГОДА, ЗА КОСМЕТОЛОГИЧЕСКУЮ ПРОДУКЦИЮ (2 ПОЗ.), НДС НЕ ОБЛАГАЕТСЯ
ПлательщикКПП=325701001
Очередность=5
КонецДокумента
СекцияДокумент=Платежное поручение
Номер=19
Дата=09.01.2025
Сумма=2053.00
ПлательщикСчет=40802810407000034776
Плательщик=ИП Мут Елена Александровна
ПлательщикИНН=246008965267
Плательщик1=ИП Мут Елена Александровна
ПлательщикРасчСчет=40802810407000034776
ПлательщикБанк1=СИБИРСКИЙ ФИЛИАЛ АО "РАЙФФАЙЗЕНБАНК"
ПлательщикБанк2=НОВОСИБИРСК
ПлательщикБИК=045004799
ПлательщикКорсчет=30101810300000000799
ПолучательСчет=40802810138000060505
ДатаПоступило=09.01.2025
Получатель=ИП Цоллер Станислав Владимирович
ПолучательИНН=561018779543
Получатель1=ИП Цоллер Станислав Владимирович
ПолучательРасчСчет=40802810138000060505
ПолучательБанк1=ПАО Сбербанк
ПолучательБанк2=Москва
ПолучательБИК=044525225
ПолучательКорсчет=30101810400000000225
КодНазПлатежа=
ВидОплаты=01
Код=0
НазначениеПлатежа=Оплата за косметические средства по договору поставки № 2366 от 29.09.2022 г. НДС не облагается
НазначениеПлатежа1=Оплата за косметические средства по договору поставки № 2366 от 29.09.2022 г. НДС не облагается
Очередность=5
КонецДокумента
СекцияДокумент=Платежное поручение
Номер=1445
Дата=10.01.2025
Сумма=23285.00
ПлательщикСчет=40702810209400308915
Плательщик=ОБЩЕСТВО С ОГРАНИЧЕННОЙ ОТВЕТСТВЕННОСТЬЮ "ГРААЛЬ"
ПлательщикИНН=9403015327
Плательщик1=ОБЩЕСТВО С ОГРАНИЧЕННОЙ ОТВЕТСТВЕННОСТЬЮ "ГРААЛЬ"
ПлательщикРасчСчет=40702810209400308915
ПлательщикБанк1=ПАО "Промсвязьбанк"
ПлательщикБанк2=Москва
ПлательщикБИК=044525555
ПлательщикКорсчет=30101810400000000555
ПолучательСчет=40802810138000060505
ДатаПоступило=10.01.2025
Получатель=ИП Цоллер Станислав Владимирович
ПолучательИНН=561018779543
Получатель1=ИП Цоллер Станислав Владимирович
ПолучательРасчСчет=40802810138000060505
ПолучательБанк1=ПАО Сбербанк
ПолучательБанк2=Москва
ПолучательБИК=044525225
ПолучательКорсчет=30101810400000000225
КодНазПлатежа=
ВидОплаты=01
Код=0
НазначениеПлатежа=Платеж по договору № 2427, от  03.08.2023г. НДС не предусмотрен
НазначениеПлатежа1=Платеж по договору № 2427, от  03.08.2023г. НДС не предусмотрен
ПлательщикКПП=940301001
Очередность=5
КонецДокумента
СекцияДокумент=Платежное поручение
Номер=6
Дата=10.01.2025
Сумма=10000.00
ПлательщикСчет=40802810422240000729
Плательщик=ИП Бысова Елена Евгеньевна
ПлательщикИНН=402900836681
Плательщик1=ИП Бысова Елена Евгеньевна
ПлательщикРасчСчет=40802810422240000729
ПлательщикБанк1=КАЛУЖСКОЕ ОТДЕЛЕНИЕ N8608 ПАО СБЕРБАНК
ПлательщикБанк2=КАЛУГА
ПлательщикБИК=042908612
ПлательщикКорсчет=30101810100000000612
ПолучательСчет=40802810138000060505
ДатаПоступило=10.01.2025
Получатель=ИП Цоллер Станислав Владимирович
ПолучательИНН=561018779543
Получатель1=ИП Цоллер Станислав Владимирович
ПолучательРасчСчет=40802810138000060505
ПолучательБанк1=ПАО Сбербанк
ПолучательБанк2=Москва
ПолучательБИК=044525225
ПолучательКорсчет=30101810400000000225
КодНазПлатежа=
ВидОплаты=01
НазначениеПлатежа=Оплата по договору за косметику по счетам 2023г.. Сумма 10000-00 Без налога (НДС)
НазначениеПлатежа1=Оплата по договору за косметику по счетам 2023г.. Сумма 10000-00 Без налога (НДС)
Очередность=5
КонецДокумента
СекцияДокумент=Платежное поручение
Номер=1444
Дата=10.01.2025
Сумма=20000.00
ПлательщикСчет=40702810209400308915
Плательщик=ОБЩЕСТВО С ОГРАНИЧЕННОЙ ОТВЕТСТВЕННОСТЬЮ "ГРААЛЬ"
ПлательщикИНН=9403015327
Плательщик1=ОБЩЕСТВО С ОГРАНИЧЕННОЙ ОТВЕТСТВЕННОСТЬЮ "ГРААЛЬ"
ПлательщикРасчСчет=40702810209400308915
ПлательщикБанк1=ПАО "Промсвязьбанк"
ПлательщикБанк2=Москва
ПлательщикБИК=044525555
ПлательщикКорсчет=30101810400000000555
ПолучательСчет=40802810138000060505
ДатаПоступило=10.01.2025
Получатель=ИП Цоллер Станислав Владимирович
ПолучательИНН=561018779543
Получатель1=ИП Цоллер Станислав Владимирович
ПолучательРасчСчет=40802810138000060505
ПолучательБанк1=ПАО Сбербанк
ПолучательБанк2=Москва
ПолучательБИК=044525225
ПолучательКорсчет=30101810400000000225
КодНазПлатежа=
ВидОплаты=01
Код=0
НазначениеПлатежа=Платеж по договору № 2427, от  03.08.2023г. НДС не предусмотрен
НазначениеПлатежа1=Платеж по договору № 2427, от  03.08.2023г. НДС не предусмотрен
ПлательщикКПП=940301001
Очередность=5
КонецДокумента
СекцияДокумент=Платежное поручение
Номер=1449
Дата=13.01.2025
Сумма=36715.00
ПлательщикСчет=40702810209400308915
Плательщик=ОБЩЕСТВО С ОГРАНИЧЕННОЙ ОТВЕТСТВЕННОСТЬЮ "ГРААЛЬ"
ПлательщикИНН=9403015327
Плательщик1=ОБЩЕСТВО С ОГРАНИЧЕННОЙ ОТВЕТСТВЕННОСТЬЮ "ГРААЛЬ"
ПлательщикРасчСчет=40702810209400308915
ПлательщикБанк1=ПАО "Промсвязьбанк"
ПлательщикБанк2=Москва
ПлательщикБИК=044525555
ПлательщикКорсчет=30101810400000000555
ПолучательСчет=40802810138000060505
ДатаПоступило=13.01.2025
Получатель=ИП Цоллер Станислав Владимирович
ПолучательИНН=561018779543
Получатель1=ИП Цоллер Станислав Владимирович
ПолучательРасчСчет=40802810138000060505
ПолучательБанк1=ПАО Сбербанк
ПолучательБанк2=Москва
ПолучательБИК=044525225
ПолучательКорсчет=30101810400000000225
КодНазПлатежа=
ВидОплаты=01
Код=0
НазначениеПлатежа=Платеж по договору № 2427, от  03.08.2023г. НДС не предусмотрен
НазначениеПлатежа1=Платеж по договору № 2427, от  03.08.2023г. НДС не предусмотрен
ПлательщикКПП=940301001
Очередность=5
КонецДокумента
СекцияДокумент=Платежное поручение
Номер=58
Дата=13.01.2025
Сумма=2390.00
ПлательщикСчет=
Плательщик=ОБЩЕСТВО С ОГРАНИЧЕННОЙ ОТВЕТСТВЕННОСТЬЮ "РЕЙН-ФАРМ"
ПлательщикИНН=9102269448
Плательщик1=ОБЩЕСТВО С ОГРАНИЧЕННОЙ ОТВЕТСТВЕННОСТЬЮ "РЕЙН-ФАРМ"
ПлательщикРасчСчет=
ПлательщикБанк1=
ПлательщикБанк2=
ПлательщикБИК=
ПлательщикКорсчет=
ПолучательСчет=40802810138000060505
ДатаПоступило=13.01.2025
Получатель=ИП Цоллер Станислав Владимирович
ПолучательИНН=561018779543
Получатель1=ИП Цоллер Станислав Владимирович
ПолучательРасчСчет=40802810138000060505
ПолучательБанк1=ПАО Сбербанк
ПолучательБанк2=Москва
ПолучательБИК=044525225
ПолучательКорсчет=30101810400000000225
КодНазПлатежа=
ВидОплаты=01
Код=0
НазначениеПлатежа=Оплата за косм. продукцию  по договору поставки №2315 от 08.11.2021.  НДС не облагается.
НазначениеПлатежа1=Оплата за косм. продукцию  по договору поставки №2315 от 08.11.2021.  НДС не облагается.
ПлательщикКПП=910201001
Очередность=5
КонецДокумента
СекцияДокумент=Платежное поручение
Номер=43
Дата=13.01.2025
Сумма=15220.00
ПлательщикСчет=
Плательщик=ОБЩЕСТВО С ОГРАНИЧЕННОЙ ОТВЕТСТВЕННОСТЬЮ "АПТЕКИ "АВИЦЕННА"
ПлательщикИНН=9102262435
Плательщик1=ОБЩЕСТВО С ОГРАНИЧЕННОЙ ОТВЕТСТВЕННОСТЬЮ "АПТЕКИ "АВИЦЕННА"
ПлательщикРасчСчет=
ПлательщикБанк1=
ПлательщикБанк2=
ПлательщикБИК=
ПлательщикКорсчет=
ПолучательСчет=40802810138000060505
ДатаПоступило=13.01.2025
Получатель=ИП Цоллер Станислав Владимирович
ПолучательИНН=561018779543
Получатель1=ИП Цоллер Станислав Владимирович
ПолучательРасчСчет=40802810138000060505
ПолучательБанк1=ПАО Сбербанк
ПолучательБанк2=Москва
ПолучательБИК=044525225
ПолучательКорсчет=30101810400000000225
КодНазПлатежа=
ВидОплаты=01
Код=0
НазначениеПлатежа=Оплата за косметическую продукцию, сог-но дог.№  № 2314 от 08.11.2021г ,  НДС не облагается.
НазначениеПлатежа1=Оплата за косметическую продукцию, сог-но дог.№  № 2314 от 08.11.2021г ,  НДС не облагается.
ПлательщикКПП=910201001
Очередность=5
КонецДокумента
СекцияДокумент=Платежное поручение
Номер=11
Дата=13.01.2025
Сумма=38986.00
ПлательщикСчет=40702810722240007603
Плательщик=ОБЩЕСТВО С ОГРАНИЧЕННОЙ ОТВЕТСТВЕННОСТЬЮ "ЦЕНТР МЕДИЦИНСКОЙ КОСМЕТОЛОГИИ ОЛЬГИ ПАХНО ВРЕМЯ КРАСОТЫ"
ПлательщикИНН=4028044044
Плательщик1=ОБЩЕСТВО С ОГРАНИЧЕННОЙ ОТВЕТСТВЕННОСТЬЮ "ЦЕНТР МЕДИЦИНСКОЙ КОСМЕТОЛОГИИ ОЛЬГИ ПАХНО ВРЕМЯ КРАСОТЫ"
ПлательщикРасчСчет=40702810722240007603
ПлательщикБанк1=КАЛУЖСКОЕ ОТДЕЛЕНИЕ N8608 ПАО СБЕРБАНК
ПлательщикБанк2=КАЛУГА
ПлательщикБИК=042908612
ПлательщикКорсчет=30101810100000000612
ПолучательСчет=40802810138000060505
ДатаПоступило=13.01.2025
Получатель=ИП Цоллер Станислав Владимирович
ПолучательИНН=561018779543
Получатель1=ИП Цоллер Станислав Владимирович
ПолучательРасчСчет=40802810138000060505
ПолучательБанк1=ПАО Сбербанк
ПолучательБанк2=Москва
ПолучательБИК=044525225
ПолучательКорсчет=30101810400000000225
КодНазПлатежа=
ВидОплаты=01
НазначениеПлатежа=Оплата за косметические средства по счету № 6378 от 08.02.2024 г.. НДС не облагается.
НазначениеПлатежа1=Оплата за косметические средства по счету № 6378 от 08.02.2024 г.. НДС не облагается.
ПлательщикКПП=402801001
Очередность=5
КонецДокумента
КонецФайла
`

export default mytext